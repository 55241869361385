import { post } from "@/utils/https";

export const uploadFile = async (file) => {
  try {
    if (!file) {
      throw "上传失败，暂无文件！";
    }

    var forms = new FormData();
    forms.append("file", file);

    const res = await post("/api/v4/nicp/files/file", forms, {
      platform: process.env.VUE_APP_AUTH_PLATFORM,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    });

    if (!res.data) throw "暂无数据";

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
